import { render, staticRenderFns } from "./CheckApprovalChip.vue?vue&type=template&id=486bf17d&"
import script from "./CheckApprovalChip.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CheckApprovalChip.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports